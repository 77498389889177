import React from "react"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "@components/Theater/TheaterVideo"

const AmbulatoryCenter = ({ content, image, youtube, hasThisSection }) => {
  if (!hasThisSection) return <></>
  return (
    <div className="columns amb-center is-vcentered">
      <div className="column is-3"></div>
      <div
        className="column is-8"
        data-aos="fade-right"
        data-aos-duration="1200"
      >
        <ImageMeta cloudname="nuvolum" publicId={image} />
      </div>
      <div className="column is-1"></div>
      <div className="column" data-aos="fade-left" data-aos-duration="1200">
        <MarkdownViewer markdown={content} />
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
          controls
          playing
          onlyButton
          language={"en"}
          buttonClass="contained"
        />
      </div>
      <div className="column is-3"></div>
    </div>
  )
}

export default AmbulatoryCenter
